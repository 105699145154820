<template>
  <PageSection
    class="Users"
    :page-title="$t('pages.settings.billing.users.headline')"
    :page-tooltip="$t('pages.settings.billing.users.sidebar_description')"
  >
    <form-wrapper :validator="$v.form">
      <div class="columns">
        <div class="column">
          <div class="p-a-m has-background-grey-lighter is-full-height">
            <Heading size="5" color="black">
              {{ $t('pages.settings.billing.users.full_access_users') }}
            </Heading>

            <div class="columns is-mobile">
              <div class="column">
                <FormField
                  :label="$t('pages.settings.billing.users.number_of_new_seats')"
                  name="licenses"
                  label-classes="has-text-transform-none is-size-6"
                  required
                >
                  <input
                    v-model.number="validator.$model"
                    slot-scope="{ validator }"
                    min="0"
                    type="number"
                    class="input"
                  >
                </FormField>
              </div>

              <div class="column has-text-centered">
                <div class="is-size-6">
                  {{ $t('pages.settings.billing.users.total_number_of_seats') }}
                </div>

                <div class="is-size-5">
                  {{ licenses }}
                </div>
              </div>
            </div>

            <div>
              {{ $t('pages.settings.billing.users.additional_cost') }}:
              {{ formatMoney(additionalUserCost, true, undefined, subscription.commitmentCurrency) }}
            </div>
          </div>
        </div>

        <div class="column">
          <div class="p-a-m has-background-grey-lighter is-full-height">
            <Heading size="5" color="black">
              {{ $t('pages.settings.billing.users.mobile_access_users') }}
            </Heading>

            <div class="columns is-mobile">
              <div class="column">
                <FormField
                  :label="$t('pages.settings.billing.users.number_of_new_seats')"
                  name="readOnlyLicenses"
                  label-classes="has-text-transform-none is-size-6"
                  required
                >
                  <input
                    v-model.number="validator.$model"
                    slot-scope="{ validator }"
                    min="0"
                    type="number"
                    class="input"
                  >
                </FormField>
              </div>

              <div class="column has-text-centered">
                <div class="is-size-6">
                  {{ $t('pages.settings.billing.users.total_number_of_seats') }}
                </div>

                <div class="is-size-5">
                  {{ readOnlyLicenses }}
                </div>
              </div>
            </div>

            <div>
              {{ $t('pages.settings.billing.users.additional_cost') }}:
              {{ formatMoney(additionalReadOnlyUsersCost, true, undefined, subscription.commitmentCurrency) }}
            </div>
          </div>
        </div>

        <div class="column">
          <div class="p-a-m has-background-grey-lighter is-full-height is-column">
            <Heading gapless size="5" color="black">
              {{ $t('pages.settings.billing.users.new_total_cost') }}
            </Heading>

            <div class="m-t-a m-b-a has-text-centered is-size-3 has-text-weight-semibold">
              {{ formatMoney(additionalUserCost + additionalReadOnlyUsersCost, true, undefined, subscription.commitmentCurrency) }}
            </div>

            <div class="has-text-centered">
              <VButton
                :loading="form.isLoading"
                class="is-primary is-wide"
                @click="processNewSeats"
              >
                {{ $t('general.confirm') }}
              </VButton>
            </div>
          </div>
        </div>
      </div>
    </form-wrapper>
  </PageSection>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import FormField from '@hypefactors/shared/js/components/forms/FormField.vue'
import Heading from '@hypefactors/shared/js/components/core/Heading.vue'
import VButton from '@hypefactors/shared/js/components/core/VButton.vue'
import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'

import PageSection from '@/components/core/PageSection.vue'
import { createPaymentPlanCommitmentForm } from '@/models/PaymentPlanCommitment'

export default {
  components: {
    Heading,
    VButton,
    FormField,
    PageSection
  },

  mixins: [FormatMoneyMixin],

  data () {
    return {
      /** @type PaymentPlanCommitmentForm */
      form: createPaymentPlanCommitmentForm()
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  computed: {
    ...mapGetters(['subscription']),
    licenses () {
      return this.$safeGet(this.subscription, 'commitmentLimitations.create-users.max', 0)
    },
    additionalUserCost () {
      // TODO: Not correct, needs updating after we decide how to handle seats ...
      return this.form.licenses * this.subscription.commitmentAmount
    },
    readOnlyLicenses () {
      return this.$safeGet(this.subscription, 'commitmentLimitations.create-read-only-users.max', 0)
    },
    additionalReadOnlyUsersCost () {
      // TODO: No idea how to make this work atm. Api is not ready yet...
      return this.form.readOnlyLicenses * this.subscription.commitmentAmount
    }
  },

  mounted () {
    this.form.merge({
      interval: this.subscription.commitmentInterval,
      plan: this.subscription.commitmentSlug
    })
  },

  methods: {
    ...mapActions(['fetchUserOrganisationsRequest']),

    async processNewSeats () {
      try {
        await this.form.post('some-url')
        this.fetchUserOrganisationsRequest()
      } catch (err) {
        this.$displayRequestError(err)
      }
    }
  }
}
</script>
